@import "awesomplete/awesomplete.css";
@import "tippy.js/dist/tippy.css";
@import "tippy.js/dist/border.css";
@import "../overrides/tippy-overrides.css";
@import "../layout/montserrat-font";
@import "../layout/bootstrap-overlap";
@import "../layout/cookieconsent";
@import "../layout/header";
@import "../layout/layout";
@import "../templates/responsive-image.css";
@import "../../css/tom-select-widget.css";
@import "../shared/variables.css";
@import "../shared/layout/bn-layout.css";
@import "../shared/components/bn-footer.css";
@import "../shared/components/bn-checkbox.css";
@import "../shared/components/bn-list.css";
@import "../shared/components/bn-controls-drawer.css";
@import "../shared/components/bn-card.css";
@import "../shared/components/bn-card-style.css";
@import "../shared/components/bn-bullet-card.css";
@import "../shared/components/bn-quote.css";
@import "../shared/components/bn-profile.css";
@import "../shared/components/bn-tile.css";
@import "../shared/components/bn-popup.css";
@import "../shared/components/bn-collapse.css";
@import "../shared/components/bn-cta.css";
@import "../shared/components/bn-alert.css";
@import "../shared/components/bn-beta-banner.css";
@import "../shared/components/bn-progress.css";
@import "../shared/components/bn-bullet.css";
@import "../shared/components/bn-bullet-point.css";
@import "../shared/components/bn-tooltip.css";
@import "../shared/components/bn-pill.css";
@import "../shared/components/bn-notification-dot.css";
@import "../shared/components/bn-cp-content-card.css";
@import "../shared/components/bn-page-header.css";
@import "../shared/utils/slide-ins.css";
@import "../shared/utils/fades.css";
@import "../shared/typography.css";
@import "../../css/bn-button.css";
@import "../modules/profile-completion.css";
@import "../modules/cookie-prompt.css";
@import "../../../../core-ui/components/pill-selectable/pill-selectable.css";
@import "@typeform/embed/build/css/popup.css";

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  overflow-x: hidden;
}

body {
  color: var(--grey-600);
  font-family: var(--font-family);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-weight: var(--font-regular);
  line-height: 1.5;
  margin: 0;
  min-width: 320px;
  position: relative;
  text-rendering: optimizelegibility;
}

a {
  color: var(--bn-link-color);
  cursor: pointer;

  &:hover {
    color: var(--bn-link-hover-color);
    text-decoration: underline;
  }

  &.external {
    display: inline-flex;
    padding-right: 1.3rem;
    position: relative;

    &::before {
      background-image: url("/static/img/icons/external-link.svg");
      background-position: center;
      background-size: contain;
      content: "";
      height: 0.8rem;
      position: absolute;
      right: 0.2rem;
      top: 50%;
      transform: translateY(-55%);
      width: 0.8rem;
    }
  }

  &.bn-btn {
    text-decoration: none;

    &:hover {
      color: initial;
    }
  }
}

button {
  color: black;
}

.navigation a,
a.card-link {
  color: var(--grey-600);
  cursor: pointer;

  &:hover {
    color: var(--bn-orange);
    text-decoration: none;
  }
}

a.hash-link,
a.hash-link:hover {
  color: var(--grey-600);
}

nav {
  display: flex;
  flex-shrink: 0;
}

nav,
.navigation {
  &.horizontal {
    justify-content: space-around;
  }

  &.vertical {
    flex-direction: column;
  }

  a.selected {
    color: var(--bn-orange);
    font-weight: var(--font-medium);
  }
}

.link {
  position: relative;

  &--advice::before {
    background-color: var(--grey-800);
    background-image: url("/static/img/icons/idea-on.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 40%;
    border-radius: 50%;
    content: "";
    filter: var(--bn-text-filter);
    height: 2rem;
    left: -2.5rem;
    position: absolute;
    top: 50%;
    transform: translateY(-55%);
    width: 2rem;
  }

  &--back {
    align-items: center;
    display: flex;

    &::before {
      background-image: url("/static/academy/icons/arrow.svg");
      background-position: left center;
      background-repeat: no-repeat;
      background-size: 0.875rem;
      content: "";
      display: inline-block;
      height: 1rem;
      position: relative;
      width: 1.25rem;
    }
  }
}

.overlay {
  background: rgba(0, 0, 0, 80%);
  bottom: 0;
  display: none;
  height: 100vh !important;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateZ(0);
  z-index: 1030;

  &.show {
    display: block;
    opacity: 1;
  }
}

.mobile-sidebar-action {
  background: none;
  border: none;
}

.container iframe {
  height: 400px;
  width: 100%;

  @media (--screen-md-max) {
    height: 350px;
  }

  @media (--screen-xs-max) {
    height: 250px;
  }
}

.zEWidget-launcher.zEWidget-launcher--active {
  @media (--screen-xs-max) {
    display: none;
  }
}

/* CkEditor flex plugin */
.flex-wrapper {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing);
  justify-content: center;
  margin: var(--spacing-l) 0;

  > div {
    padding: var(--spacing);
  }
}

.logo-container {
  position: relative;
}

.bn-hide {
  display: none !important;
  opacity: 0;
}

ol.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  font-size: var(--font-sm);
  list-style: none;
  margin: 0;
  padding: var(--spacing) 0 0 0;

  li {
    overflow: hidden;
    padding-right: 0.5rem;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;

    &::after {
      color: var(--bn-link-color);
      content: "\276F";
      margin-left: 0.2rem;
    }

    &:last-of-type {
      a {
        color: black;
      }
    }

    &:last-of-type::after {
      content: "";
    }

    @media only screen and (--screen-md-max) {
      &:not(&:first-of-type, &:last-of-type) {
        display: none;
      }
    }
  }
}
