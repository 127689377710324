.bn-footer {
  background: rgb(2, 0, 36);
  background: linear-gradient(
    0deg,
    rgba(2, 0, 36, 100%) 0%,
    rgba(234, 235, 236, 100%) 0%,
    rgba(245, 247, 250, 100%) 100%
  );
  color: var(--grey-300);
  font-size: var(--font-xs);
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: auto;
  font-weight: var(--font-light);

  &__wrapper,
  &__nav {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &__wrapper {
    padding: var(--spacing-xl) var(--bs-gutter-x, 0.9375rem);

    @media (--screen-xlg-max) {
      padding: var(--spacing-xl) var(--bs-gutter-x, 0.9375rem) 5rem;
    }

    @media (--screen-sm) {
      grid-template-columns: 1fr;
    }
  }

  &__nav {
    > div {
      display: flex;
      flex-direction: column;

      > * {
        padding: var(--spacing-xxs) 0;
      }
    }

    @media (--screen-xs) {
      grid-template-columns: 1fr;
      padding-left: 1.5rem;
    }
  }

  a {
    color: var(--grey-600);

    &:hover {
      color: var(--bn-orange);
      text-decoration: underline;
    }
  }
}
