:root {
  /* typography */
  --font-extra-light: 200;
  --font-light: 300;
  --font-regular: 400;
  --font-medium: 500;
  --font-semi-bold: 600;
  --font-bold: 700;
  --font-family: "Montserrat", sans-serif;
  --font-xxs: 0.625rem;
  --font-xs: 0.75rem;
  --font-base: 1rem;
  --font-sm: 0.875rem;
  --font-lg: 1.125rem;
  --font-xlg: 1.5rem;
  --font-xxlg: 2rem;

  /* base colours */

  /* TODO: change the below to use closest `tw-` value */
  --black: #000;
  --white: #fff;
  --grey-800: #f1f1f1;
  --grey-600: #242424;
  --grey-500: #333;
  --grey-400: #3e414a;
  --grey-300: #707070;
  --grey-200: #e5e5e5;
  --grey-100: #f5f7fa;
  --border-grey-1: #ddd;
  --border-grey-2: #d8d8d8;
  --border-grey-3: #888;
  --border-grey-4: #aaa;
  --border-grey-5: #c4c1c1;
  --border-grey-6: #f0f1f2;

  /* tailwind variables

     In some cases (e.g., Tomselect) where we don't have access to the HTML,
     we must use CSS variables. We are naming them the same as in the Tailwind
     config to make the eventual migration easier. */
  --tw-grey-600: #242424;
  --tw-grey-500: #65696c;
  --tw-grey-400: #93989b;
  --tw-grey-300: #aab1b5;
  --tw-grey-200: #c6cdd0;
  --tw-grey-100: #dbe2e5;
  --tw-grey-75: #e0e8ec;
  --tw-grey-50: #eaf0f3;
  --tw-grey-25: #eff3f5;
  --tw-grey-15: #f5f8fa;
  --tw-blue-15: #ebf2fe;

  /* semantic colours */
  --bn-default-bg: #f69044;
  --bn-default-bg-hover: #d28043;
  --bn-default-bg-active: #b56f3b;
  --bn-default-bg-disabled: #d7a47b;
  --bn-text-muted: #838383;
  --bn-text: #242424;
  --bn-text-hover: #292929;
  --bn-text-active: #000;
  --bn-text-disabled: #3f3f3f;
  --bn-text-secondary: #3f3f3f;
  --bn-success-bg: #65ccb5;
  --bn-success-bg-hover: #5bb6a2;
  --bn-success-bg-active: #50ad99;
  --bn-success-bg-disabled: #8db1a8;
  --bn-success-color: #3f3f3f;
  --bn-success-color-hover: #64a092;
  --bn-success-color-active: #4d7d72;
  --bn-success-color-disabled: #3f3f3f;
  --bn-success-color-secondary: #2c8471;
  --bn-warning-bg: #ffe497;
  --bn-danger-bg: #d53d60;
  --bn-danger-bg-hover: #af415a;
  --bn-danger-bg-active: #863245;
  --bn-danger-bg-disabled: #bb4f68;
  --bn-danger-color: #fff;
  --bn-danger-color-hover: #af415a;
  --bn-danger-color-active: #863245;
  --bn-danger-color-disabled: #fff;
  --bn-danger-color-secondary: #d53d60;
  --bn-secondary-color-disabled: #767676;
  --bn-light-blue-hover: #5789d4;
  --bn-light-blue-background: #f5f8fa;
  --bn-pistachio-background: #e8f7f4;

  /* brand colours */
  --bn-pistachio: #65ccb5;
  --bn-academy-orange: #f69143;
  --bn-dark-blue: #0b4764;
  --bn-light-blue: #76a8f4;
  --bn-orange: #f69044;
  --bn-dark-link: #16181b;
  --bn-light-green: #a0d7c8;
  --bn-dark-green: #65ccb5;
  --bn-light-purple: #b4a0ff;
  --bn-athens-gray: #e9ebee;
  --bn-mustard: #ffca30;
  --bn-green: #73c8b3;
  --bn-raspberry: #d94161;
  --bn-hot-red: #ff194b;
  --ieuk-blue: #498bff;
  --bn-dark-teal: #004b50;
  --bn-light-grey-homepage: #242424;
  --bn-dark-grey-footer: #363838;
  --bn-grey-header-search: #f0f1f2;
  --bn-red: #d9506f;
  --bn-burnt-orange: #843200;
  --nav-bar-font-color: #2c4964;
  --nav-bar-pill-background-color: #ebf2fe;
  --nav-bar-pill-active-background-color: #bad3f9;
  --bn-link-color: #b35907;
  --bn-link-hover-color: #9b4500;

  /* Filters */
  --bn-danger-filter: invert(37%) sepia(58%) saturate(2331%) hue-rotate(322deg)
    brightness(86%) contrast(94%);
  --bn-success-filter: invert(69%) sepia(43%) saturate(384%) hue-rotate(116deg)
    brightness(97%) contrast(92%);
  --bn-orange-filter: invert(71%) sepia(13%) saturate(6049%) hue-rotate(331deg)
    brightness(104%) contrast(93%);
  --bn-warning-filter: invert(73%) sepia(70%) saturate(465%) hue-rotate(353deg)
    brightness(99%) contrast(105%);
  --bn-white-filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(108deg)
    brightness(104%) contrast(104%);
  --bn-text-filter: invert(10%) sepia(22%) saturate(0%) hue-rotate(165deg)
    brightness(91%) contrast(88%);
  --bn-light-blue-filter: invert(63%) sepia(15%) saturate(5216%)
    hue-rotate(193deg) brightness(107%) contrast(91%);

  /* RGB */
  --bn-athens-gray-rgb: 233, 235, 238;
  --bn-orange-rgb: 246, 144, 68;
  --bn-light-blue-rgb: 118, 168, 244;
  --bn-light-purple-rgb: 180, 160, 255;
  --bn-pistachio-rgb: 101, 204, 181;
  --bn-mustard-rgb: 255, 202, 48;
  --bn-success-rgb: 59, 181, 131;
  --bn-danger-rgb: 217, 80, 111;
  --bn-warning-rgb: 255, 202, 48;
  --bn-raspberry-rgb: 217, 65, 97;
  --bn-green-rgb: 115, 200, 179;
  --bn-dark-blue-rgb: 11, 71, 100;
  --bn-academy-orange-rgb: 246, 145, 67;

  /* semi-opaque */
  --bn-orange-opc: #fff9f5;
  --bn-light-blue-opc: #f5f9ff;
  --bn-athens-gray-opc: #f9f9f9;
  --bn-light-purple-opc: #f9f7ff;
  --bn-pistachio-opc: #f8fffd;
  --bn-mustard-opc: #fdf9ef;
  --bn-hot-red-opc: #fff6f8;
  --bn-raspberry-opc: #fff6f8;
  --bn-orange-faded: rgba(246, 144, 68, 50%);
  --bn-faq-orange-faded: #ffefe3;

  /* Spacing - t-shirt sized */
  --spacing: 1rem;
  --spacing-m: 1.5rem;
  --spacing-l: 2rem;
  --spacing-xl: 3.125rem;
  --spacing-xxl: 6.25rem;
  --spacing-s: 0.875rem;
  --spacing-xs: 0.625rem;
  --spacing-xxs: 0.5rem;
  --spacing-xxxs: 0.25rem;
  --section-supplementary-padding: 15px;

  /* styling */
  --box-shadow:
    rgba(0, 0, 0, 2%) 0 1.25rem 2.688rem, rgba(0, 0, 0, 1%) 0 0.25rem 1rem,
    rgba(0, 0, 0, 2.4%) 0 0.125rem 0.375rem, rgba(0, 0, 0, 1%) 0 0 0.125rem;
  --box-shadow-hover:
    rgba(0, 0, 0, 2%) 0 1.25rem 2.688rem, rgba(0, 0, 0, 1%) 0 0.25rem 1rem,
    rgba(0, 0, 0, 2.4%) 0 0.125rem 0.375rem, rgba(0, 0, 0, 1%) 0 0 0.125rem;
  --box-shadow-inset: inset 0 -8px 8px rgba(0, 0, 0, 1%);
  --box-shadow-light: 0 0 12px rgba(0, 0, 0, 10%);
  --box-border: 1px solid var(--border-grey-1);
  --box-border-dashed: 2px dashed var(--border-grey-2);
  --bang-height: 5px;

  /* Old border radius */
  --box-border-radius: 2rem;
  --box-border-radius-s: 9px;

  /* override bootstrap container size (equal to bn-header's inner width) */
  --container-l: 1428px;

  /* breakpoints */
  --screen-xs: 576px;
  --screen-sm: 768px;
  --screen-md: 992px;
  --screen-lg: 1200px;
  --screen-xlg: 1440px;
  --screen-xxs-max: (--screen-xs - 1);
  --screen-xs-max: (--screen-sm - 1);
  --screen-sm-max: (--screen-md - 1);
  --screen-md-max: (--screen-lg - 1);
  --screen-lg-max: (--screen-xlg - 1);
  --employer-banner-max-width: 1398px;

  /* line height */
  --line-height-01: 1;
  --line-height-02: 1.25;
  --line-height-03: 1.5;
}
